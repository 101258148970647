import pickBy from 'lodash.pickby';

const checkValueNotEmpty = (v: any) => {
	return v !== null && v !== undefined && v !== '';
};

const checkIsEmptyObject = (v: Record<string, unknown>) => {
	let isEmpty = true;
	for (let key in v) {
		if (checkValueNotEmpty(v[key])) {
			isEmpty = false;
		}
	}

	return isEmpty;
};

export const filterArrayFromEmpty = (v: unknown[]) => {
	return v.filter(item => {
		if (typeof item === 'object') {
			return !checkIsEmptyObject(item as Record<string, unknown>);
		} else {
			return checkValueNotEmpty(item);
		}
	});
};

export const filterArrayFieldsFromEmptyGroups = (v: any) => {
	const res = { ...v };
	for (let key in res) {
		if (Array.isArray(res[key])) {
			res[key] = filterArrayFromEmpty(res[key]);
		}
	}
	return res;
};

export const removeAllEmpty = (obj: any | undefined): any => {
	if (!obj) return {};

	return pickBy(obj, (v: string | null) => checkValueNotEmpty(v));
};
