/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

export enum UserTypeEnum {
	SELLER = 'seller',
	BUYER = 'buyer',
	ADMINISTRATOR = 'administrator',
	LAWYER = 'lawyer',
	ANALYST = 'analyst',
	SALES_MANAGER = 'sales manager',
}
